<template>
    <router-link to="/">
        <div><img src="../../assets/pixiv.svg" alt="" /></div>
    </router-link>
</template>

<script>
export default {
    name: "PLogo",
};
</script>

<style scoped>
div {
    max-width: 100%;
    max-height: 100%;
}
</style>
